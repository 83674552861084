import styled from "styled-components"

const ListContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 1.5;
  color: var(--theme-ui-colors-grey-90, #232129);
  text-align: start;
  align-self: stretch;
  flex-grow: 1;
`

const ListItem = styled.li`
  display: flex;
  padding: 0% 2% 0% 0%;

  @media screen and (min-width: 1025px) {
  }

  @media screen and (max-width: 1024px) {
  }

  @media screen and (max-width: 767px) {
    padding: 0%;

    h6 {
      width: 92.5%;
    }
  }
`

// const ListItemHeader = styled.h6`

//   @media screen and (min-width: 1025px) {
//   }

//   @media screen and (max-width: 1024px) {

//   }
// `

// const ListItemContentWrapper = styled.div`

//   @media screen and (min-width: 1025px) {
//   }

//   @media screen and (max-width: 1024px) {

//   }
// `

export { ListContainer, ListItem }
