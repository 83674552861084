import React from "react"
import { Fade } from "react-awesome-reveal"
import styled from "styled-components"
import * as Buttons from "../components/Buttons"
import * as Containers from "../components/Containers"
import * as Icons from "../components/Icons"
import Layout from "../components/layout"
import * as Lists from "../components/Lists"
import SEO from "../components/seo"
import "../css/styles.css"
import LandingBodyS1Graphics from "../images/artwork/Landing-BodyS1-Graphics_v3.svg"
import LandingBodyS2AbstractShape from "../images/artwork/Landing-BodyS2-AbstractShape_v3.svg"
import LandingBodyS2Graphics from "../images/artwork/Landing-BodyS2-Graphics_v4.svg"
import LandingBodyS3Graphics from "../images/artwork/Landing-BodyS3-Graphics_v1.svg"
import LandingCTABackground from "../images/artwork/Landing-CTA-Background_v1.svg"
import LandingHeroGraphics from "../images/artwork/Landing-Hero-Graphics_v6.svg"
import LandingHeroGraphicsMobile from "../images/Landing-Hero-Graphics-Mobile.png"
import Line from "../images/Line_v1.inline.svg"
import Pointer from "../images/Pointer_v1.inline.svg"
import Tick from "../images/Tick_v2.inline.svg"

import TextTransition, { presets } from "react-text-transition"

const TEXTS = [
  "Sourcing for Quotes",
  "Managing Transactions",
  "Handling Client Relationships",
  "Managing your Agency",
]

const IndexPage = () => {
  const [index, setIndex] = React.useState(0)

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex(index => index + 1),
      3000 // every 3 seconds
    )
    return () => clearTimeout(intervalId)
  }, [])

  return (
    <Layout>
      <SEO title="Home" />
      <PageContainer>
        <Containers.SectionContainer>
          <HeroContent>
            <HeroTextWrapper>
              <HeroTextContainer>
                <HeroText triggerOnce>
                  <StyledTextLoop>
                    <h1>
                      <TextTransition
                        text={TEXTS[index % TEXTS.length]}
                        springConfig={presets.stiff}
                      />
                    </h1>
                  </StyledTextLoop>
                  <h1 style={{ fontWeight: 500 }}>should be easy</h1>
                </HeroText>
              </HeroTextContainer>
            </HeroTextWrapper>
            <HeroSubtext>
              The centralised Insurtech platform that simplifies your business
              with a personal touch.
            </HeroSubtext>
            <HeroButton to="/contact">Request Demo Now</HeroButton>
          </HeroContent>
          <HeroBg>
            <img
              src={LandingHeroGraphics}
              height="100%"
              width="100%"
              alt="HeroBg"
            />
          </HeroBg>
          <HeroBgMobile>
            <img
              src={LandingHeroGraphicsMobile}
              height="100%"
              width="100%"
              alt="HeroBg"
            />
          </HeroBgMobile>
        </Containers.SectionContainer>

        <Containers.SectionContainer>
          <S1Content triggerOnce>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <PAlt className="title">DIGITISE</PAlt>
              <div style={{ alignSelf: "center" }}>
                <h2>Digitally Elevating the People Business</h2>
              </div>
              <S1Subtext>
                <S1Line />
                <h5 style={{ marginBottom: 0 }}>
                  <span>The modern solution for insurance professionals</span>
                </h5>
              </S1Subtext>
              <S1Bg>
                <img
                  src={LandingBodyS1Graphics}
                  width="100%"
                  height="100%"
                  alt="S1Bg"
                />
              </S1Bg>
              <S1TextContainer>
                <p>
                  Using digital solutions might seem complicated, but providing
                  value to your clients now needs an unfair advantage more than
                  ever.
                </p>
                <p>
                  Our easy-to-use platform features enable you to streamline
                  your general insurance work, from building client
                  relationships to efficiently managing your cases.
                </p>
              </S1TextContainer>
            </div>
          </S1Content>
        </Containers.SectionContainer>

        <S2SectionContainer>
          <S2BgContainer triggerOnce>
            <S2Bg src={LandingBodyS2Graphics} alt="S2Bg" />
          </S2BgContainer>
          <S2Content triggerOnce>
            <div>
              <h2>Making it simple for you when you work</h2>
              <h5 style={{ marginBottom: "1.875rem" }}>
                <span>
                  With your business processes managed and streamlined, you can
                  prioritise focus on your clients!
                </span>
              </h5>
              <Lists.ListContainer>
                <Lists.ListItem>
                  <Icons.TickIcon />
                  <div style={{ marginBottom: 15 }}>
                    <h6
                      style={{
                        marginBottom: 15,
                      }}
                    >
                      Smart-Efficiency will be the new standard in your workflow
                    </h6>
                    <p>
                      Consolidate important client documents - proposal forms
                      and policy documents with our standardised workflows,
                      making your work fuss-free.
                    </p>
                  </div>
                </Lists.ListItem>
                <Lists.ListItem>
                  <Icons.TickIcon />
                  <div style={{ marginBottom: 15 }}>
                    <h6
                      style={{
                        marginBottom: 15,
                      }}
                    >
                      Track, plan and follow-up all at once
                    </h6>
                    <p>
                      Business management and administrative matters can be
                      rather time-consuming. <br /> Our centralised dashboard
                      provides a snapshot to actively track, manage and act in
                      quick succession on-the-fly!
                    </p>
                  </div>
                </Lists.ListItem>
                <Lists.ListItem>
                  <Icons.TickIcon />
                  <div style={{ marginBottom: 15 }}>
                    <h6
                      style={{
                        marginBottom: 15,
                      }}
                    >
                      Strengthen your client’s trust in you
                    </h6>
                    <p>
                      Be better prepared for your clients, with a touch of your
                      personality in your services and brand. <br />
                      Our relationship tagging feature helps you track your
                      clients' needs and wants!
                    </p>
                  </div>
                </Lists.ListItem>
              </Lists.ListContainer>
              <Buttons.ButtonLinkArrowed to="/features">
                See how you can benefit from our features
                <Icons.RightArrowIcon />
              </Buttons.ButtonLinkArrowed>
            </div>
          </S2Content>
        </S2SectionContainer>

        <DividerImg src={LandingBodyS2AbstractShape} alt="S2AbstractShape" />

        <Containers.SectionContainer>
          <S3Content triggerOnce>
            <div
              style={{
                alignSelf: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <S3Header>
                <h2>Work digitally across all channels</h2>
                <h5>
                  <span>
                    Have a small, lean organisation that is looking to scale? or
                    a partnership with multiple sales & marketing managers?
                    <br />
                    Uuoni stands by to support your growth, with a range of
                    simple and easy-to-use solutions.
                  </span>
                </h5>
              </S3Header>

              <S3Bg />

              <Containers.ProductGridContainer>
                <Containers.ProductGridContainerItem>
                  <CapitalCharacter className="capital">T</CapitalCharacter>
                  <Containers.ProductGridContainerItemContent>
                    <h4 style={{ alignSelf: "center" }}>TEAM</h4>
                    <div style={{ flexGrow: 1 }}>
                      <div
                        style={{
                          display: "flex",
                          marginBottom: "1.875rem",
                        }}
                      >
                        <Pointer
                          style={{
                            minWidth: "1rem",
                            height: "1rem",
                            marginRight: 20,
                            marginTop: 5,
                          }}
                        />
                        <p style={{ marginBottom: 0 }}>
                          Having good internal communication is as important as
                          the external.
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          marginBottom: "1.875rem",
                        }}
                      >
                        <Pointer
                          style={{
                            minWidth: "1rem",
                            height: "1rem",
                            marginRight: 20,
                            marginTop: 5,
                          }}
                        />
                        <p style={{ marginBottom: 0 }}>
                          Track and analyse your team's performance better with
                          organised, easy-to-understand reporting features.
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          marginBottom: "1.875rem",
                        }}
                      >
                        <Pointer
                          style={{
                            minWidth: "1rem",
                            height: "1rem",
                            marginRight: 20,
                            marginTop: 5,
                          }}
                        />
                        <p style={{ marginBottom: 0 }}>
                          Improve your team's camaraderie with healthy,
                          strategic collaborations and peer assessments!
                        </p>
                      </div>
                    </div>
                    <Buttons.ButtonLinkArrowed to="/contact">
                      Uncover your potential
                      <Icons.RightArrowIcon />
                    </Buttons.ButtonLinkArrowed>
                  </Containers.ProductGridContainerItemContent>
                </Containers.ProductGridContainerItem>
                <Containers.ProductGridContainerItem
                  style={{ marginLeft: "auto" }}
                >
                  <CapitalCharacter className="capital">E</CapitalCharacter>
                  <Containers.ProductGridContainerItemContent>
                    <h4 style={{ alignSelf: "center" }}>ENTERPRISE</h4>
                    <div style={{ flexGrow: 1 }}>
                      <div
                        style={{
                          display: "flex",
                          marginBottom: "1.875rem",
                        }}
                      >
                        <Pointer
                          style={{
                            minWidth: "1rem",
                            height: "1rem",
                            marginRight: 20,
                            marginTop: 5,
                          }}
                        />
                        <p style={{ marginBottom: 0 }}>
                          Explore how our purpose-built solutions can be
                          incorporated to an existing system or constructed to
                          suit your organisation's needs!
                        </p>
                      </div>
                    </div>
                    <Buttons.ButtonLinkArrowed to="/contact">
                      Let's Discuss
                      <Icons.RightArrowIcon />
                    </Buttons.ButtonLinkArrowed>
                  </Containers.ProductGridContainerItemContent>
                </Containers.ProductGridContainerItem>
              </Containers.ProductGridContainer>
            </div>
          </S3Content>
        </Containers.SectionContainer>

        <CTAContainer>
          <CTABg src={LandingCTABackground} />
          <Containers.CTAContent>
            <h2 style={{ marginTop: "2%" }}>
              Set up your next digital solution
            </h2>
            <Buttons.ButtonLink to="/contact">
              Request Demo Now
            </Buttons.ButtonLink>
          </Containers.CTAContent>
        </CTAContainer>
      </PageContainer>
    </Layout>
  )
}

export default IndexPage

const PageContainer = styled(Containers.PageContainer)`
  @media screen and (max-width: 1024px) {
    gap: 1.5rem;
  }
`

const CTAContainer = styled(Containers.CTAContainer)`
  :before {
    z-index: 1;
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    top: 0;
    bottom: 0;
    background-color: #ffffff96;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    margin-bottom: -10%;
  }

  @media screen and (min-width: 768px) {
    margin-top: -10%;
  }
`

const HeroBg = styled.div`
  z-index: 1;

  @media screen and (min-width: 768px) {
    min-width: 70%;
    transform: scale(1.05) translateX(-18%);
    margin-top: -1%;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
}
`

const HeroBgMobile = styled.div`
  z-index: 1;

  @media screen and (min-width: 768px) {
    display: none;
  }

  @media screen and (max-width: 767px) {
    transform: translateX(3.5%);
    width: 130%;
  }
}
`

const PAlt = styled.p`
  position: absolute;
  transform: translateX(-15%);
  margin-top: 1.5rem;

  @media screen and (min-width: 1025px) {
  }

  @media screen and (max-width: 767px) {
  }
`

const S1Bg = styled.div`
  width: 100%;
  margin: -6% auto;
  transform: scale(1.12);
  z-index: 1;

  @media screen and (max-width: 1024px) {
    transform: scale(1.22);
  }

  @media screen and (max-width: 767px) {
    transform: scale(1.45);
    margin: 11% auto 9% auto;
  }
`

const S2BgContainer = styled(Fade)`
  width: 70%;

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    margin-top: -8%;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    order: 2;
  }
`

const S2Bg = styled.img`
  @media screen and (min-width: 1025px) {
    transform: translateX(-10%);
    padding-top: 5%;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    transform: translateX(-6%);
  }
`

const S3Bg = styled.div`
  transform: scale(1.5);
  margin: 10% 15%;
  background-image: url(${LandingBodyS3Graphics});
  background-repeat: no-repeat;
  background-size: 80%;
  height: 60vw;
  width: 100%;

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    transform: scale(1.5) translateX(3%);
    margin: 10% 15%;
    background-image: url(${LandingBodyS3Graphics});
    background-repeat: no-repeat;
    background-size: 75%;
    height: 50vw;
    width: 100%;
  }

  @media screen and (min-width: 1025px) {
    display: none;
  }
`

const DividerImg = styled.img`
  @media screen and (min-width: 1025px) {
    margin-bottom: -20%;
    margin-top: -9%;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    margin-top: -12%;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: -10%;
    margin-top: -20%;
  }
`

const CTABgOld = styled.div`
  position: relative;
  background-image: url(${LandingCTABackground});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  height: 100%;
  width: 100%;

  @media screen and (max-width: 1024px) {
  }

  @media screen and (max-width: 767px) {
    background-size: 140%;
  }

  ::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: #ffffff96;
  }
`

const CTABg = styled.img`
  position: relative;
  height: 100%;
  width: 100%;

  @media screen and (max-width: 1024px) {
  }

  @media screen and (max-width: 767px) {
    background-size: 140%;
  }
`

const HeroContent = styled.div`
  min-width: 55%;
  margin: auto;

  @media screen and (min-width: 768px) {
    padding-bottom: 5%;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    text-align: left;
    margin-bottom: 5%;
    height: 100%;

    h1 {
      line-height: unset;
    }
  }
`

const HeroText = styled(Fade)`
  margin-bottom: 0px;
  width: 100%;
  display: block;

  @media screen and (min-width: 1025px) {
    width: 100%;
  }

  @media screen and (max-width: 767px) {
  }
`

const HeroTextWrapper = styled.div`
  height: 12rem;
  width: 100%;
  display: flex;
  align-items: flex-end;
`

const HeroTextContainer = styled.div`
  display: block;
  width: 100%;
`

const HeroSubtext = styled.p`
  margin-bottom: 1.875rem;
  width: 65%;
  font-weight: 400;

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 60%;
    margin-right: auto;
  }

  @media screen and (max-width: 767px) {
    width: 95%;
    margin-right: auto;
  }
`

const S1Content = styled(Fade)`
  width: 100%;
  margin: auto;

  @media screen and (min-width: 1025px) {
  }

  @media screen and (max-width: 1024px) {
  }
`

const S1Subtext = styled.div`
  @media screen and (min-width: 1025px) {
    margin-right: 6%;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
  }

  @media screen and (min-width: 768px) {
    flex-grow: 1;
    align-self: flex-end;
    margin-top: 2.5%;
    display: flex;
    align-items: center;
  }
`

const S1Line = styled(Line)`
  height: 3rem;
  margin-bottom: 5px;

  @media screen and (min-width: 1025px) {
    margin-right: -2%;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 7rem;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`

const S1TextContainer = styled.div`
  align-self: center;
  text-align: center;
  margin-top: -10%;
  width: 100%;

  @media screen and (min-width: 1025px) {
    width: 50%;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 80%;
  }

  @media screen and (max-width: 767px) {
    text-align: left;
  }
`

const S2SectionContainer = styled(Containers.SectionContainer)`
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    align-items: center;
    flex-direction: column;
    text-align: left;
    line-height: unset;
  }
`

const S2Content = styled(Fade)`
  width: 100%;
  margin: auto;

  @media screen and (min-width: 1025px) {
    width: 55%;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    a {
      float: right;
    }
  }

  @media screen and (max-width: 1024px) {
  }
`

const TickIcon = styled(Tick)`
  margin-right: 20px;

  @media screen and (min-width: 768px) {
    min-width: 3rem;
    height: 3rem;
  }

  @media screen and (max-width: 767px) {
    min-width: 24pt;
    height: 24pt;
  }
`

const S3Content = styled(Fade)`
  width: 100%;
  margin: auto;
  position: relative;

  height: 100%;

  @media screen and (min-width: 1025px) {
    background-image: url(${LandingBodyS3Graphics});
    background-repeat: no-repeat;
    background-size: min(800px, 35vw);
    background-position: 50% 80%;
  }

  @media screen and (max-width: 1024px) {
  }
`

const S3Header = styled.div`
  width: 100%;

  @media screen and (min-width: 1025px) {
    width: 45%;
  }

  @media screen and (max-width: 767px) {
    width: 95%;
  }
`

const StyledTextLoop = styled.div`
  width: 100%;
  display: block;

  h1 {
    margin-bottom: 0;
    color: #5e80e6;
  }

  @media screen and (min-width: 1025px) {
  }

  @media screen and (max-width: 767px) {
    text-align: left;
    width: 90%;
    -webkit-transform: translate3D(0, 0, 0);

    div {
      -webkit-transform: translate3D(0, 0, 0);
    }
  }
`

const CapitalCharacter = styled.p`
  position: absolute;
  transform: translate(25%, -90%);

  @media screen and (min-width: 1025px) {
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    transform: translate(-5%, -90%);
  }

  @media screen and (max-width: 767px) {
    transform: translate(-5%, -90%);
  }
`

const HeroButton = styled(Buttons.ButtonLink)`
  @media screen and (min-width: 1025px) {
    margin-bottom: 40px;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    margin-bottom: 40px;
  }
  @media screen and (max-width: 767px) {
  }
`
